import React, { useState, useLayoutEffect } from "react"
import { graphql } from "gatsby"
import { analyticClickEvent } from "../../components/googleAnalytics"
import {
  ArticleDate,
} from "../../styledComponents/article"
import LayoutNoStripe from "../../components/layoutNoStripe"
import SEO from "../../components/seo"
import {
  Section,
  SectionHeading,
} from "../../styledComponents/section"
import { BackgroundColourBlock } from "../../styledComponents/overView"
import {
  StyledVideoTestimonialArticle,
  StyledVideoTestimonialHeader,
  StyledVideoTestimonialVideo,
  StyledVideoTestimonialLede
} from "../../styledComponents/videoTestimonial"
import EventList from "../../components/EventList"
import { StyledButton } from "../../styledComponents/button"
import ShareTools from "../../components/socialLinks"
import { StyledShareSocialTools } from "../../components/Products/StyledPrimaryProductCard"
import { StyledBlockQuote } from "../../styledComponents/base"
import Icon from "../../components/icon"


const Heading = ({ level = 1, location, children, align }) => (
  <SectionHeading as={`h${level}`} level={level} location={location} align={align}>
    {children}
  </SectionHeading>
)

const DebbieGilesVideo = ({ data, location }) => {
  

  const [isWatchFullVersionOpen, setIsWatchFullVersionOpen] = useState(false);
  const [isAutopPlay, setAutoPlay] = useState(false);

  const toggleTestimonials = (value) => {
    analyticClickEvent("engagement", "CTA Button", "Watch Full Richard Gordon Video", '')
    setIsWatchFullVersionOpen(value)
  }

  const pageUrl = `https://${process.env.GATSBY_DOMAIN_NAME}/quantum-touch/an-interview-with-richard-gordon-founder-of-quantum-touch`

  useLayoutEffect(() => {
    if (typeof window !== "undefined" || window.document) {
      const urlParams = new URLSearchParams(window.location.search)
      const autoplay = urlParams.get('autoplay')
      setAutoPlay(autoplay)
      return
    }
  }, [])
  return (
    <LayoutNoStripe displayNavBorder location={location} hideNewsletterSignUp>
      <SEO
        title=""
        description=""
        url="/quantum-touch/debbie-giles-qualified-quantum-touch-practitioner"
        image="https://www.karinagrant.co.uk/images/karina-grant-interviews-richard-gordon-placeholder.jpg"
      />
      <StyledVideoTestimonialArticle itemScope itemType="http://schema.org/Article">
        <StyledVideoTestimonialHeader>
          <h1 itemProp="headline">Quantum-Touch Practitioner - A video from the lovely Debbie Giles</h1>
          <ArticleDate offScreen dateTime=''>Last updated on January 17th 2024</ArticleDate>
        </StyledVideoTestimonialHeader>
        <StyledVideoTestimonialVideo>

          <figure class="wp-block-embed is-type-video is-provider-youtube wp-block-embed-youtube wp-embed-aspect-16-9 wp-has-aspect-ratio"><div class="wp-block-embed__wrapper"><iframe loading="lazy" title="A video of Debbie Giles Quantum-Touch practitioner" width="640" height="360" src={'https://karinagrant.s3.eu-west-1.amazonaws.com/videos/testimonials/Debbie+Giles+-+QT+Level+1+Testimonial.mp4'} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;" controlsList="nodownload" allowfullscreen=""></iframe></div></figure>
        </StyledVideoTestimonialVideo>

        <StyledVideoTestimonialLede>I met Debbie in 2015 when she attended my Quantum-Touch Level 1 workshop.</StyledVideoTestimonialLede><StyledVideoTestimonialLede>She has since attended all her workshops with me, qualified as a Quantum-Touch practitioner and it was a joy to officially mentor Debbie through her Quantum-Touch Level 1 instructor training.</StyledVideoTestimonialLede>
        <StyledVideoTestimonialLede>Thank you Debbie. You teach us all just how much a person’s life can positively do a 180, especially with a skillset like Quantum-Touch to help do so. I have loved our sessions.</StyledVideoTestimonialLede>
        

        
        <StyledBlockQuote>
        <StyledVideoTestimonialLede style={{"marginBottom":"1.6rem"}}>Debbie’s words after our mentoring sessions</StyledVideoTestimonialLede>
            <Icon name="singlequote" />
          <StyledVideoTestimonialLede>“Wow, and WoW! 🙌🏾<br /><br />I can't believe that I can now share this wonderful modality that is Quantum Touch with people on a whole new level. Everyone needs some QT in their life and everyone should have some good empowering tools to honour themselves with. </StyledVideoTestimonialLede>
          <StyledVideoTestimonialLede>We all need to heal, grow and have the ability to take full power of our well-being and health, no matter your background. You have a set of cards handed to you in life, how do you play them? This is totally up to you! My advice; grow, grow and glow.. QT, QT, QT, this is what worked for me! And I am more than grateful 😌🙏🏾🌿</StyledVideoTestimonialLede>
          <StyledVideoTestimonialLede>Thank you Karina, you are more than a mentor, you are a genuine, warm human being on this planet, making the world a better place and I am blessed to have met you. I can't thank you enough for sharing this modality with me and many, many others.
          </StyledVideoTestimonialLede>
          <StyledVideoTestimonialLede>If I teach just half as good as you, I'll definitely be on the winning side 😉🙌🏾🙏🏾🌿</StyledVideoTestimonialLede>
          <StyledVideoTestimonialLede>And as for Richard Gordon you are one blessed man and I cherish the day you met Bob and decided to take pen to paper, watch, learn and share. We now have the same dream, everyone needs a Quantum Touch toolbox in their world .. and watch this planet fly high, higher, highest 🙌🏾🙌🏾🙌🏾🙌🏾 <br />Grateful! 💛💎💛</StyledVideoTestimonialLede>

          <StyledVideoTestimonialLede>Debbie Giles, London</StyledVideoTestimonialLede>

        
        </StyledBlockQuote>

        <StyledShareSocialTools type="video">
          { }
          <ShareTools shareTitle="Share this on" pageLocation={pageUrl} postTitle='An message from Debbie Gile a qualified Quantum-Touch Practitoner' />
        </StyledShareSocialTools>
        </StyledVideoTestimonialArticle>

       

      

      
    </LayoutNoStripe>
  )
}

export default DebbieGilesVideo

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }

    wpPage(slug: { eq: "homepage" }) {
      title
      slug
      content
      testimonials {
        blockquote {
          citeLocation
          citationName
          quote
        }
        testimonialHeading
        testimonialLede
      }
    }

    aboutKarina: wpPage(slug: { eq: "about" }) {
      aboutKarina {
        aboutKarina
      }
      featuredImage {
        node {
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
    }
      

    allWpPost(sort: { fields: [date], order: DESC } limit: 5) {
      nodes {
        excerpt
        uri
        date(formatString: "MMMM DD, YYYY")
        title
        isSticky
        categories {
          nodes {
            slug
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 975
                  quality: 100
                  placeholder: TRACED_SVG
                  breakpoints: [320, 720, 1024, 1600]
                )
              }
            }
          }
        }
      }
    }


    allWpProduct(limit: 3) {
      edges {
        node {
          title
          uri
          excerpt
          productsTag {
            nodes {
              name
            }
          }
          productData {
            pricefull
            pricesale
            stripefullprice
            stripesaleprice
            hidesale
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
      }
    }
}
`
